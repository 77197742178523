import { parseDate } from "@internationalized/date";
import cn from "classnames";
import { graphql, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement, useEffect, useRef, useState } from "react";

import Button from "../../../../../atoms/button/Button";
import PageStepAnimation from "../../../../../atoms/page-step-animation/PageStepAnimation";
import TrackableLink from "../../../../../atoms/trackable-link/TrackableLink";
import { DealEventPropertiesDTO } from "../../../../../organisms/deal-event-properties/domain/deal-event-properties";
import {
  useGetDealEventProperties,
  useSetDealEventProperties,
} from "../../../../../organisms/deal-event-properties/use-cases/deal-event-properties-use-cases";
import { Result } from "../../../../../organisms/result/domain/result";
import {
  useResult,
  useSetResultState,
} from "../../../../../organisms/result/use-cases/result-use-cases";
import { DiscountCampaigns } from "../../../../../settings/discounts";
import { isPetYoungerThanMinAge, PetSpecies } from "../../../../../settings/pet";
import { Events, track } from "../../../../../utils/analytics";
import { getCurrentURLSearchParams } from "../../../../../utils/browser-features";
import { parseToCalendarDate } from "../../../../../utils/date";
import { handleGetPromotionCodeErrors } from "../../../../../utils/error-utils/catch-error-handlers";
import useTrackPageViewed from "../../../../../utils/hooks/useTrackPageViewed";
import {
  isPolicyStartDateChangeAvailable,
  shouldAllowBecasDiscount,
  shouldAllowFakeChipNumber,
  shouldNavigateToSmallPrintPages,
  shouldShowAlternativeHealthProductSelector,
  shouldShowGlAddon,
} from "../../../../../utils/locale-configuration-utils";
import { rollbar, RollbarLogArgument } from "../../../../../utils/rollbar";
import {
  useHealthProductConditions,
  useHealthProductFeatures,
} from "../../../application/product-use-cases";
import {
  useGetPromotionCode,
  usePromotionCode,
} from "../../../application/promotion-code-use-cases";
import { usePersistCart, useShoppingCart } from "../../../application/shopping-cart-use-cases";
import AlternativeHealthProductSelector from "../../organisms/health-product-selector/AlternativeHealthProductSelector";
import HealthProductSelector from "../../organisms/health-product-selector/HealthProductSelector";
import PaymentIntervalMonthsSelector from "../../organisms/payment-interval-months-selector/PaymentIntervalMonthsSelector";
import PolicyStartDateSelector from "../../organisms/policy-start-date-selector/PolicyStartDateSelector";
import * as styles from "./Quotes.module.scss";

interface QuotesProps {
  conditionsList: Record<string, string[] | string>[];
  productFeaturesList: { locale: string; entryTitle: string; features: string[] }[];
}

const Quotes = ({ conditionsList, productFeaturesList }: QuotesProps): ReactElement => {
  const currentURLSearchParams = getCurrentURLSearchParams();
  const noEditable = currentURLSearchParams?.get("no_editable");
  const utmCampaign = currentURLSearchParams?.get("utm_campaign");
  const getPromotionCode = useGetPromotionCode();
  const promotionCode = usePromotionCode();
  const hasBecasDiscount =
    (utmCampaign && utmCampaign in DiscountCampaigns) ||
    (promotionCode && promotionCode.campaignName in DiscountCampaigns);
  const result = useResult() as Result;
  const setResult = useSetResultState();
  const { t } = useTranslation();
  const cart = useShoppingCart();
  const persistCart = usePersistCart();
  const getDealEventProperties = useGetDealEventProperties();
  const setDealEventProperties = useSetDealEventProperties();
  const shouldShowAlternateBenefits =
    shouldShowGlAddon(result.country) && result.pet_species === PetSpecies.dog;

  const healthBenefitsList = useHealthProductConditions(
    conditionsList,
    result.country,
    result.pet_species,
    shouldShowAlternateBenefits ? "benefits-alternate" : "benefits"
  );

  const healthProductsFeatures = useHealthProductFeatures(productFeaturesList, result?.country);
  const [isLoading, setIsLoading] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [hasHealthProductError, setHasHealthProductError] = useState<boolean>(false);
  const [hasPaymentIntervalMonthsError, setHasPaymentIntervalMonthsError] =
    useState<boolean>(false);
  const showAlternativeHealthProductSelector = shouldShowAlternativeHealthProductSelector(
    result.country
  );
  const [hasInvalidPolicyStartDate, setHasInvalidPolicyStartDate] = useState<boolean>(false);
  const [hasSubmitError, setHasSubmitError] = useState<boolean>(false);
  const hasFakeChipNumber: boolean = shouldAllowFakeChipNumber(result?.country);
  const isDisabledSubmit =
    isFirstRender ||
    hasHealthProductError ||
    hasPaymentIntervalMonthsError ||
    hasInvalidPolicyStartDate;
  const initialPolicyStartDateRef = useRef(result.policy_start_date);
  const nextUrl = (): string => {
    if (noEditable) {
      return `/results/${result?.uuid}/details/?no_editable=1`;
    }

    if (hasFakeChipNumber) {
      return `/results/${result?.uuid}/pet_parent/`;
    }

    return `/results/${result?.uuid}/${
      shouldNavigateToSmallPrintPages(result?.country) ? "small_print/" : "has_chip/"
    }`;
  };

  const handleChangePolicyStartDate = (value: string): void => {
    setResult({ policy_start_date: value });
  };

  const handleSubmit = async (): Promise<void> => {
    const properties = {
      eventSender: "Quotes Page CTA",
      ...(isDisabledSubmit && {
        buttonDisabled: isDisabledSubmit,
        paymentIntervalMonthsUnselected: !cart.paymentIntervalMonths,
        healthProductUnselected: cart.isNeededSelectHealthProduct,
      }),
    };

    track(Events.CLICKED_CTA, properties);

    if (isDisabledSubmit) {
      handleValidationErrors();

      return;
    }

    try {
      setIsLoading(true);

      await persistCart();

      if (getDealEventProperties) {
        try {
          const dealEventProperties: DealEventPropertiesDTO = await getDealEventProperties();
          setDealEventProperties(dealEventProperties);
        } catch (err: any) {
          if (err instanceof Error) {
            setHasSubmitError(true);
            rollbar.error(err.message, err, {
              context: "getDealEventProperties/index",
            });
          }
        }
      }

      await navigate(nextUrl());
    } catch (err: any) {
      rollbar.warn("Error on submit", err as RollbarLogArgument, {
        context: "results/index",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleValidationErrors = () => {
    setIsFirstRender(false);
    setHasPaymentIntervalMonthsError(!cart.paymentIntervalMonths);
    setHasHealthProductError(cart.isNeededSelectHealthProduct);
  };

  useEffect((): void => {
    const setBecasDiscount = async (): Promise<void> => {
      if (!utmCampaign) {
        return;
      }

      try {
        await getPromotionCode(result.country, DiscountCampaigns[utmCampaign], utmCampaign);
      } catch (error) {
        handleGetPromotionCodeErrors(error, "PromotionCodeForm");
      }
    };

    if (result && shouldAllowBecasDiscount(result?.country) && hasBecasDiscount) {
      void setBecasDiscount();
    }
  }, []);

  useEffect(() => {
    if (cart.paymentIntervalMonths && !cart.isNeededSelectHealthProduct) {
      setIsFirstRender(false);
    }
  }, [cart.isNeededSelectHealthProduct, cart.paymentIntervalMonths, result.country]);

  useTrackPageViewed(Events.QUOTES_DISPLAYED_BROWSER, {
    isPetYoungerThanMinAge: isPetYoungerThanMinAge(result.pet_birth_date),
  });

  if (result && result.uncoveredReason) {
    void navigate(`/results/${result.uuid}/uncovered/`);

    return <></>;
  }

  return (
    <>
      {result && (
        <>
          <PageStepAnimation>
            {showAlternativeHealthProductSelector ? (
              <AlternativeHealthProductSelector
                benefits={healthBenefitsList}
                features={healthProductsFeatures}
                hasHealthProductError={hasHealthProductError}
                setHasHealthProductError={setHasHealthProductError}
                {...(hasBecasDiscount && { hasBecasDiscount: true })}
              />
            ) : (
              <HealthProductSelector
                benefits={healthBenefitsList}
                features={healthProductsFeatures}
                isDisabled={isLoading}
                hasHealthProductError={hasHealthProductError}
                setHasHealthProductError={setHasHealthProductError}
                {...(hasBecasDiscount && { hasBecasDiscount: true })}
              />
            )}
            {(isPetYoungerThanMinAge(result.pet_birth_date) ||
              isPolicyStartDateChangeAvailable(result.country)) && (
              <PolicyStartDateSelector
                initialValue={parseDate(result?.policy_start_date)}
                updateValue={handleChangePolicyStartDate}
                hasValidationError={hasInvalidPolicyStartDate}
                setHasValidationError={setHasInvalidPolicyStartDate}
                {...(isPolicyStartDateChangeAvailable(result.country) && {
                  customMinValue: parseToCalendarDate(initialPolicyStartDateRef.current),
                })}
                isDisabled={!isPolicyStartDateChangeAvailable(result.country)}
              />
            )}
            {!hasBecasDiscount && (
              <PaymentIntervalMonthsSelector
                isDisabled={isLoading}
                hasPaymentIntervalMonthsError={hasPaymentIntervalMonthsError}
                setHasPaymentIntervalMonthsError={setHasPaymentIntervalMonthsError}
              />
            )}
            <div className={styles.submitButtonWrapper}>
              {hasSubmitError && <p className={styles.errorMessage}>{t("common.error_message")}</p>}

              <Button
                type="submit"
                isLoading={isLoading}
                onClick={handleSubmit}
                aria-disabled={cart.selectedProductsTotalPrice === 0 || isDisabledSubmit}
                adoptionClassName={cn(styles.submitButton, {
                  [styles.isDisabledSubmit]: isDisabledSubmit,
                })}
              >
                {t("common.cta.continue")}
              </Button>
              <div className={styles.downloadConditionsMessage}>
                <p>
                  {t("pg_quotes.conditions.text")}{" "}
                  <TrackableLink
                    to={t("common.policy_example.source")}
                    eventSender={"Sample policy link"}
                    openInNewWindow
                  >
                    {t("pg_quotes.conditions.link.text")}
                  </TrackableLink>{" "}
                  {result.hasGlQuote && (
                    <>
                      {t("pg_quotes.gl_policy_example.text")}{" "}
                      <TrackableLink
                        to={t("common.gl_policy_example.source")}
                        eventSender={"GL Sample policy link"}
                        openInNewWindow
                      >
                        {t("pg_quotes.gl_policy_example.link.text")}
                      </TrackableLink>
                    </>
                  )}
                </p>
              </div>
            </div>
          </PageStepAnimation>
        </>
      )}
    </>
  );
};

export const query = graphql`
  fragment ConditionsFragment on ContentfulConditionsList {
    node_locale
    type
    species
    conditionsItems {
      items
      subtitle
      title
      type
    }
  }
`;

export default Quotes;
