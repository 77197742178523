import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import parse, { Element, HTMLReactParserOptions } from "html-react-parser";
import { Dispatch, ReactElement, SetStateAction } from "react";

import Heading from "../../../../../atoms/heading/Heading";
import CheckList from "../../../../../molecules/check-list/CheckList";
import { Result } from "../../../../../organisms/result/domain/result";
import { useResult } from "../../../../../organisms/result/use-cases/result-use-cases";
import { CountryCode } from "../../../../../settings/countries";
import { HealthProductAlias, ProductId } from "../../../../../settings/products";
import { Events, track } from "../../../../../utils/analytics";
import { formatCurrency } from "../../../../../utils/currency";
import { shouldShowBaiLinkOnPrevention } from "../../../../../utils/locale-configuration-utils";
import { usePromotionCode } from "../../../application/promotion-code-use-cases";
import {
  useSelectHealthProduct,
  useShoppingCart,
} from "../../../application/shopping-cart-use-cases";
import { Product } from "../../../domain/product";
import { ShoppingCart } from "../../../domain/shopping-cart";
import { PaymentIntervalMonths } from "../../../settings/payments";
import ButtonCardSelector from "../../atoms/button-card-selector/ButtonCardSelector";
import QuotesInfoCard from "../../atoms/quotes-info-card/QuotesInfoCard";
import AlternativeHealthProductCard from "../../molecules/health-product-card/AlternativeHealthProductCard";
import WhatsAppDialog from "../whatsapp-dialog/WhatsAppDialog";
import * as styles from "./AlternativeHealthProductSelector.module.scss";

interface AlternateHealthProductSelectorProps {
  benefits: any;
  features: Record<string, string | string[]>[];
  hasBecasDiscount?: boolean;
  hasHealthProductError?: boolean;
  setHasHealthProductError: Dispatch<SetStateAction<boolean>>;
}

const AlternativeHealthProductSelector = ({
  benefits,
  features,
  hasBecasDiscount,
  hasHealthProductError,
  setHasHealthProductError,
}: AlternateHealthProductSelectorProps): ReactElement => {
  const { t, i18n } = useTranslation();
  const result: Result | null = useResult();
  const cart: ShoppingCart = useShoppingCart();
  const selectHealthProduct = useSelectHealthProduct();
  const promotionCode = usePromotionCode();

  const getDiscountedPrice = (productId: ProductId) => {
    return promotionCode && hasBecasDiscount
      ? parse(
          t("pg_quotes.health_product_selector.monthly", {
            productPrice: formatCurrency(
              cart.getMonthlyDiscountedPriceByHealthProduct(productId),
              i18n.language,
              result?.currency
            ),
          })
        )
      : undefined;
  };

  const getHealthProductName = (product: Product): string => {
    if (cart.getProduct(ProductId.healthPlatinum)) {
      switch (product.id) {
        case ProductId.healthBasic:
          return HealthProductAlias.healthBasic;
        case ProductId.healthPlatinum:
          return HealthProductAlias.healthPlatinum;
        default:
          return product.name;
      }
    }

    return product.name;
  };

  const getHealthProductPrice = (productId: ProductId) => {
    return parse(
      t(
        `pg_quotes.health_product_selector.${
          cart.paymentIntervalMonths === PaymentIntervalMonths.yearly ? "yearly" : "monthly"
        }`,
        {
          productPrice: formatCurrency(
            cart.getPriceByHealthProduct(productId),
            i18n.language,
            result?.currency
          ),
        }
      )
    );
  };

  const getHealthProductFeatures = (productId: ProductId) => {
    const getFeatures = (healthAlias: HealthProductAlias): string[] => {
      return features
        .filter((item: { locale: string; entryTitle: string; features: string[] }) => {
          return item.entryTitle.toLowerCase() === healthAlias;
        })
        .flatMap(
          (item: { locale: string; entryTitle: string; features: string[] }) => item.features
        )
        .slice(0, -1);
    };

    switch (productId) {
      case ProductId.healthPlatinum:
        return getFeatures(HealthProductAlias.healthPlatinum);
      case ProductId.healthBasic:
      default:
        return getFeatures(HealthProductAlias.healthBasic);
    }
  };

  const healthProducts = cart
    .getHealthProducts()
    .map((healthProduct: Product) => {
      return {
        value: healthProduct.id as ProductId,
        name: getHealthProductName(healthProduct),
        features: getHealthProductFeatures(healthProduct.id),
        price: getHealthProductPrice(healthProduct.id),
      };
    })
    .sort((a, b): number => (a.name > b.name ? -1 : 1));

  const getBenefitIcon = (benefitType: string) => {
    switch (benefitType) {
      case "prevention":
        return "syringe";
      case "health":
      default:
        return "bag";
    }
  };

  const handleSelectHealthProduct = (value: ProductId): void => {
    track(Events.ITEM_SELECTED, {
      eventSender: "health-product-card",
      selection: value,
    });

    setHasHealthProductError(false);
    selectHealthProduct(value);
  };

  return (
    <section className={cn(styles.alternativeHealthProductSelector)}>
      <Heading level={1} adoptionClassName={styles.title}>
        {t("pg_quotes.health_product_selector.title", { petName: result?.pet_name })}
      </Heading>

      <QuotesInfoCard
        visibilityTrackerId={"alternative-health-product-selector"}
        adoptionClassName={styles.productSelectorWrapper}
        icon={"smiling-heart"}
      >
        <ul>
          {healthProducts.map((healthProduct) => {
            return (
              <li key={healthProduct.value}>
                <ButtonCardSelector
                  handleClick={() => handleSelectHealthProduct(healthProduct.value)}
                  isSelected={cart.getSelectedHealthProduct()?.id === healthProduct.value}
                >
                  <AlternativeHealthProductCard
                    healthProduct={healthProduct}
                    discountedPrice={getDiscountedPrice(healthProduct.value)}
                    appliedPromotionCode={promotionCode?.code}
                  />
                </ButtonCardSelector>
              </li>
            );
          })}
        </ul>
        <p className={styles.disclaimer}>
          {t("pg_quotes.alternative_health_product_selector.disclaimer")}
        </p>
      </QuotesInfoCard>

      {benefits.conditionsItems.map((benefit, index) => {
        const transformHeadingTitle = (benefit: { type: string; title: string }) => {
          if (
            benefit.type !== "prevention" ||
            !shouldShowBaiLinkOnPrevention(result?.country as CountryCode)
          ) {
            return parse(benefit.title);
          }
          const options: HTMLReactParserOptions = {
            replace: (domNode) => {
              if (domNode instanceof Element && domNode.name === "span") {
                return (
                  <WhatsAppDialog
                    label={t("pg_quotes.alternative_health_product_selector.whatsapp_dialog.label")}
                    countryCode={result?.country as CountryCode}
                    eventSender={"Prevention Benefits Section"}
                  />
                );
              }
            },
          };

          return parse(benefit.title, options);
        };

        const getItemsList = (type: string) => {
          const items = [...benefit.items];

          switch (type) {
            case "prevention":
              return items;
            case "health":
              items.unshift(t("pg_quotes.health_product_selector.benefit.waiting_time"));

              return items;
            default:
              return items;
          }
        };

        return (
          benefit.items && (
            <QuotesInfoCard
              visibilityTrackerId={`${benefit.type}-benefits-card`}
              key={index}
              headingTitle={transformHeadingTitle(benefit)}
              icon={getBenefitIcon(benefit.type)}
            >
              <CheckList
                key={index}
                id={`health-product-benefits`}
                items={getItemsList(benefit.type)}
                adoptionClassName={styles.benefitsCheckList}
              />
            </QuotesInfoCard>
          )
        );
      })}

      {hasHealthProductError && (
        <p className={styles.errorMessage}>{t("pg_quotes.health_product_selector.error")}</p>
      )}
    </section>
  );
};

export default AlternativeHealthProductSelector;
